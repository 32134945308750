import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Layout from "../components/Layout"
import HeroHeader from "../components/HeroHeader"
import HeroImage from "../images/application-hero-image.jpg"
import Head from "../components/Head"
import ApplicationSummary from "../components/ApplicationSummary"
import SEO from "../components/SEO"

import WarehouseImage from "../images/application-thumbnails/warehouseTEST.jpg"
import AerospaceImage from "../images/application-thumbnails/aerospace.jpg"
import AviationImage from "../images/application-thumbnails/aviation.jpg"
import BuildingImage from "../images/application-thumbnails/buildingservices.jpg"
import BulkImage from "../images/application-thumbnails/bulkpackaging.jpg"
import CasinoImage from "../images/application-thumbnails/casino.jpg"
import DistributionImage from "../images/application-thumbnails/distribution.jpg"
import EducationImage from "../images/application-thumbnails/education.jpg"
import FoodImage from "../images/application-thumbnails/foodbeverage.jpg"
import GovernmentImage from "../images/application-thumbnails/government.jpg"
import LocalGovernment from "../images/application-thumbnails/localgovernment.jpg"
import ManufacturingImage from "../images/application-thumbnails/manufacturing.jpg"
import ProduceImage from "../images/application-thumbnails/produce.jpg"
import RetailImage from "../images/application-thumbnails/Audimas_retail_store.jpg"
import SportsImage from "../images/application-thumbnails/sports.jpg"
import AutomotiveImage from "../images/application-thumbnails/automotive.jpg"
import HospitalityImage from "../images/application-thumbnails/hospitality.jpg"
import HealthcareImage from "../images/application-thumbnails/healthcare.jpg"

const BodyContainer = styled.div`
  max-width: 1400px;
  margin: 1.5rem auto;

  /* @media (max-width: 300px) {
    width: 100%;
  } */
`

const ApplicationContainer = styled.div`
  margin: 3rem auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  /* max-width: 1400px; */
`

const ApplicationsPage = () => {
  return (
    <Layout>
      <SEO title="Applications" description="" />
      <Head title="applications" />
      <HeroHeader
        heroImage={HeroImage}
        title="FactoryCat Applications"
        subHeading="We have the right machine for you."
        buttonTitle="See Applications"
        goTo="#walkie-scrubbers"
      />
      <BodyContainer>
        {/* <h1>Cleaning Solutions by Industry</h1>
        <p>
          Cold storage warehousing poses a unique challenge for scrubbers, as
          the clean solution used must be able to accommodate the temperatures
          without freezing - For which we have our "Freezer Package" option.
        </p> */}
        <ApplicationContainer>
          <ApplicationSummary
            image={AerospaceImage}
            applicationName="Aerospace"
            applicationDescription="High-performance cleaning capabilities offered is unparalleled in the floor machine market and ideal for the Aerospace market.  "
            link="/applications/aerospace"
          />
          <ApplicationSummary
            image={AviationImage}
            applicationName="Aviation"
            applicationDescription="Aviation is a specialty field and Factory Cat cylindrical scrubbers are particularly popular in the aircraft hangars."
            link="/applications/aviation"
          />
          <ApplicationSummary
            image={BuildingImage}
            applicationName="Building Services"
            applicationDescription="Building Service Contractors perhaps are the most dependant of any client on their sweeper, burnisher or scrubber's reliability."
            link="/applications/building-services"
          />
          <ApplicationSummary
            image={BulkImage}
            applicationName="Bulk Packaging"
            applicationDescription="Powder and Bulk Packaging accounts deal with volumes of dust and material that is not found normally in other applications."
            link="/applications/bulk-packaging"
          />
          <ApplicationSummary
            image={CasinoImage}
            applicationName="Casino & Resort"
            applicationDescription=" Casinos offer and accommodate different type of gambling activities, so the need to scrub up tears (of sadness and joy) and spilled beverages is common."
            link="/applications/casino-and-resort"
          />
          <ApplicationSummary
            image={DistributionImage}
            applicationName="Distribution"
            applicationDescription="Factory Cat offers an ideal line of floor scrubbers and warehouse sweepers for a variety of distribution type facilities. Due to the frequency of broken pallet chips, nails, spilled product, many customers now elect to choose one of our scrubber-sweeper combination machines. "
            link="/applications/distribution"
          />
          <ApplicationSummary
            image={EducationImage}
            applicationName="Education"
            applicationDescription="Educational buildings, whether for Universities or K-12 Grade schools meet the challenges of scrubbing and sweeping on a daily basis. Often asked to scrub more square footage each year, with smaller janitorial staffs, many are looking to the compact rider scrubbers and and rider sweepers built by Factory Cat."
            link="/applications/education"
          />
          <ApplicationSummary
            image={FoodImage}
            applicationName="Food & Beverage"
            applicationDescription="Facilities that process, package or redistribute food and beverages have unique needs and high expectations. A floor scrubber that cleans effectively, while containing the dust and fluids well is highly sought. Factory Cat has always enjoyed a strong customer base with the produce packing house."
            link="/applications/food-and-beverage"
          />
          <ApplicationSummary
            image={GovernmentImage}
            applicationName="Government"
            applicationDescription="Federal Government agencies are very diverse in the world wide market and can utilizes our smallest walk-behind scrubbers and walk-behind sweepers to our largest Rider Scrubbers and Sweeper for the many locations and applications."
            link="/applications/government"
          />
          <ApplicationSummary
            image={HealthcareImage}
            applicationName="Health Care"
            applicationDescription="With a growing population seeking ever better medical care. To better serve hospitals and assisted care facilities, our battery powered scrubbers have been designed to operate with the least amount of noise and make water recovery a key. "
            link="/applications/health-care"
          />
          <ApplicationSummary
            image={HospitalityImage}
            applicationName="Hospitality"
            applicationDescription="Hospitality industry have a wide variety of surfaces to scrub or sweep. Our customers have a need to sweep large parking areas, vacuum carpeting around the guest sleeping areas, scrubbing the grease and spills in the large kitchens, and burnishing the floor finish in lobbies. "
            link="/applications/hospitality"
          />
          <ApplicationSummary
            image={LocalGovernment}
            applicationName="Gov State Local"
            applicationDescription="State and Local governments and their agencies are very diverse in their equipment needs and can utilizes our smallest walk-behind scrubbers and walk-behind sweepers to our largest Rider Scrubbers and Sweepers for the many locations and applications. "
            link="/applications/gov-state-local"
          />
          <ApplicationSummary
            image={ManufacturingImage}
            applicationName="Manufacturing"
            applicationDescription='Even with the latest dust collection systems and modern fabrication equipment, there is something about the "manufacturing" process that tends to make a mess, which our sweeper-scrubber combo machines are ideally suited for. Manufacturing facilities often house industrial machine, material handling equipment, large pillars and other items which are potential collision hazards. '
            link="/applications/manufacturing"
          />
          <ApplicationSummary
            image={ProduceImage}
            applicationName="Produce Packaging"
            applicationDescription="Our battery scrubbers' fully accessible recovery tank, allows for unsurpassed sanitation and disinfecting of the recovery (dirty) tank. The Factory Cat scrubbers are used to recover the fluid spills from the many Wash Baths that are required, and also clean up the soil tracked around by the material handling vehicles."
            link="/applications/produce-packaging"
          />
          <ApplicationSummary
            image={RetailImage}
            applicationName="Retail"
            applicationDescription="Retail locations for some of the world's largest companies are moving away from the cheap imported sweepers and scrubbers, and turning to our products which are custom and handmade in the USA. While known for the eye for savings, many of the large box stores have found the small premium they invest in our scrubbers pays off quickly with reduced down time and repair bills. "
            link="/applications/retail"
          />
          <ApplicationSummary
            image={SportsImage}
            applicationName="Sports Arena"
            applicationDescription="Sports Arenas often have a limited time to do the housekeeping chores before the next event. Some even have to tackle multiple sports and flooring, back to back. Scrubbing wood floors requires supreme water control and water recovery from the scrubber. The areas away from the playing fields are often concrete, with a need to sweep up liter like food."
            link="/applications/sports-arena"
          />
          <ApplicationSummary
            image={AutomotiveImage}
            applicationName="Truck / Automotive"
            applicationDescription=" Many automobile dealerships have discovered the labor savings available with our battery scrubber's cylindrical scrub deck feature. By sweeping and scrubbing in a single path, their operators can clean around service bays without clogged squeegee hoses or streaked floors. "
            link="/applications/truck-automotive"
          />
          <ApplicationSummary
            image={WarehouseImage}
            applicationName="Warehouse"
            applicationDescription="Warehousing companies often invest in a scrubber-sweeper from Factory Cat, to keep inventory items free of dust while in storage or transit. We have seen a marked increase in the number of customers who are using the Walk Behind Sweeper and Compact Rider Sweeper (TR) to clean the inside of trailers while at the loading docks."
            link="/applications/warehouse"
          />
          {/* <ApplicationSummary
            image={WarehouseImage}
            applicationName="Metal Fabrication and Distribution"
            applicationDescription="As a taxpayer I really like to see that our government and their contractors spend the money we have entrusted them with buying Made in the USA products from domestic businesses. Factory Cat offers the government some of the most effective, most affordable, best built floor .  "
            link="/applications/metal-fabrication"
          /> */}
          {/* <div
            style={{ width: "40px", height: "40px", backgroundColor: "blue" }}
          ></div> */}
          {/* <div className="img-container">
            <img src={WarehouseImage} alt="aerospace" />
          </div>
          <div className="img-content">
            <h2>Warehouse</h2>
            <p style={{ maxWidth: "400px" }}>
              Cold storage warehousing poses a unique challenge for scrubbers,
              as the clean solution used must be able to accommodate the
              temperatures without freezing - For which we have our "Freezer
              Package" option.
            </p>
            <Link to="/applications/warehouse">
              <button>View More</button>
            </Link>
          </div>
          <div className="img-container">
            <img src={WarehouseImage} alt="aerospace" />
          </div>
          <div className="img-content">
            <h2>Warehouse</h2>
            <p style={{ maxWidth: "400px" }}>
              Cold storage warehousing poses a unique challenge for scrubbers,
              as the clean solution used must be able to accommodate the
              temperatures without freezing - For which we have our "Freezer
              Package" option.
            </p>
            <Link to="/applications/warehouse">
              <button>View More</button>
            </Link>
          </div> */}
        </ApplicationContainer>
      </BodyContainer>
    </Layout>
  )
}

export default ApplicationsPage
