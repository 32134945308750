import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const StyledContainer = styled.div`
  width: 650px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 2rem auto;

  @media (max-width: 1300px) {
    width: 500px;
  }

  @media (max-width: 1000px) {
    width: 100%;
    padding: 2rem;
  }

  @media (max-width: 600px) {
    padding: 0.5rem;
    display: block;
  }

  img {
    width: 300px;
    height: 200px;
    object-fit: cover;

    @media (max-width: 1300px) {
      width: 250px;
    }

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .content {
    margin: 0 1.5rem;
    h2 {
      margin: 0;
      border-bottom: 3px solid red;
    }
    p {
    }
    button {
      color: white;
      background-color: red;
      border: none;
      padding: 0.5rem;
      cursor: pointer;
    }
  }
`

const ApplicationSummary = props => {
  return (
    <StyledContainer>
      <div className="img-container">
        <img src={props.image} alt={props.alt} />
      </div>
      <div className="content">
        <h2>{props.applicationName}</h2>
        <p>{props.applicationDescription}</p>
        <Link
          style={{ textDecoration: "none", color: "white" }}
          to={props.link}
        >
          <button>View More</button>
        </Link>
      </div>
    </StyledContainer>
  )
}

export default ApplicationSummary
