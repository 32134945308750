import React from "react"
import styled from "styled-components"
import SeeMoreButton from "./controls/SeeMoreButton"

const HeroContainer = styled.div`
  position: relative;
  /* width: 100%; */
  height: 30vh;
  /* h1 {
    margin: 0;
    width: 500px;
    text-align: center;
    position: absolute;
    z-index: 4;
    bottom: 0;
    color: white;
    left: 50%;
    margin-left: -250px;
    @media (max-width: 420px) {
      width: 350px;
      margin-left: -175px;
    }
  } */
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-content {
    /* max-width: 1400px; */
    position: absolute;
    text-align: center;
    font-size: 2em;
    color: white;
    bottom: 50%;
    margin-bottom: -72.5px;
    left: 50%;
    margin-left: -346px;
    z-index: 2;

    h1 {
      margin: 0;
    }
    p {
      margin: 0;
    }
    @media (max-width: 670px) {
      font-size: 1.5em;
      margin-bottom: -54.5px;
      margin-left: -260px;
    }

    @media (max-width: 500px) {
      font-size: 1em;
      margin-bottom: -36px;
      margin-left: -173px;
    }
  }

  .see-more {
    text-align: center;
    font-size: 2em;
    position: absolute;
    bottom: 100px;
    left: 50%;
    margin-left: -135px;
  }

  .overlay {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
  }
`

const HeroHeader = props => {
  return (
    <HeroContainer>
      <img src={props.heroImage} />
      <div className="hero-content">
        <h1>{props.title}</h1>
        <p>{props.subHeading}</p>
      </div>

      <div className="overlay"></div>
      {/* <h1 style={{ fontSize: "1.7em" }}>
        Outstanding<span> QUALITY</span> and
        <span> DURABILITY</span>
      </h1> */}
    </HeroContainer>
  )
}

export default HeroHeader
